import * as React from "react";
import Helmet from "react-helmet";

import {
  CaseStudy,
  Clients,
  Feedback,
  Feature,
  Expertise,
  Form,
  Values,
  Hero,
  Services,
  Team,
  Footer,
  Gallery
} from "../components";

const seo = {
  title: `Home`,
  description: `Passionate about your events`
};

const Index = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="twitter:description" content={seo.description} />
      </Helmet>

      <Hero.Vsr index={Math.floor(Math.random() * 3)} />
      <Services.Full />
      <Expertise.Full />
      <Feedback.Min />
      <Team.Mid limit={true} />
      <Gallery.Min />
      <CaseStudy.Min />
      <Form.Contact />
      <Footer />
    </>
  );
};

export default Index;
